[data-color-mode="light"][data-light-theme="light_high_contrast"],
[data-color-mode="light"][data-light-theme="light_high_contrast"] ::backdrop,
[data-color-mode="auto"][data-light-theme="light_high_contrast"],
[data-color-mode="auto"][data-light-theme="light_high_contrast"] ::backdrop {
  --topicTag-borderColor: #0349b4;
  --highlight-neutral-bgColor: #fcf7be;
  --page-header-bgColor: #e6eaef;
  --diffBlob-additionLine-fgColor: #010409;
  --diffBlob-additionLine-bgColor: #d2fedb;
  --diffBlob-additionWord-fgColor: #ffffff;
  --diffBlob-additionWord-bgColor: #055d20;
  --diffBlob-additionNum-fgColor: #010409;
  --diffBlob-additionNum-bgColor: #82e596;
  --diffBlob-deletionLine-fgColor: #010409;
  --diffBlob-deletionLine-bgColor: #fff0ee;
  --diffBlob-deletionWord-fgColor: #ffffff;
  --diffBlob-deletionWord-bgColor: #a0111f;
  --diffBlob-deletionNum-fgColor: #010409;
  --diffBlob-deletionNum-bgColor: #ffc1bc;
  --diffBlob-hunkLine-bgColor: #dff7ff;
  --diffBlob-hunkLine-fgColor: #454c54;
  --diffBlob-hunkNum-fgColor-rest: #010409;
  --diffBlob-hunkNum-fgColor-hover: #ffffff;
  --diffBlob-hunkNum-bgColor-rest: #9cd7ff;
  --diffBlob-hunkNum-bgColor-hover: #0349b4;
  --diffBlob-emptyNum-bgColor: #e6eaef;
  --diffBlob-emptyLine-bgColor: #e6eaef;
  --diffBlob-expander-iconColor: #010409;
  --codeMirror-fgColor: #010409;
  --codeMirror-bgColor: #ffffff;
  --codeMirror-gutters-bgColor: #ffffff;
  --codeMirror-gutterMarker-fgColor-default: #ffffff;
  --codeMirror-gutterMarker-fgColor-muted: #454c54;
  --codeMirror-lineNumber-fgColor: #454c54;
  --codeMirror-cursor-fgColor: #010409;
  --codeMirror-selection-bgColor: #368cf9;
  --codeMirror-activeline-bgColor: #e0e6eb;
  --codeMirror-matchingBracket-fgColor: #010409;
  --codeMirror-lines-bgColor: #ffffff;
  --codeMirror-syntax-fgColor-comment: #010409;
  --codeMirror-syntax-fgColor-constant: #023b95;
  --codeMirror-syntax-fgColor-entity: #622cbc;
  --codeMirror-syntax-fgColor-keyword: #a0111f;
  --codeMirror-syntax-fgColor-storage: #a0111f;
  --codeMirror-syntax-fgColor-string: #032563;
  --codeMirror-syntax-fgColor-support: #023b95;
  --codeMirror-syntax-fgColor-variable: #702c00;
  --header-fgColor-default: #ffffffb3;
  --header-fgColor-logo: #ffffff;
  --header-bgColor: #25292e;
  --header-borderColor-divider: #c8d1da;
  --headerSearch-bgColor: #25292e;
  --headerSearch-borderColor: #818b98;
  --data-blue-color-emphasis: #006edb;
  --data-blue-color-muted: #d1f0ff;
  --data-auburn-color-emphasis: #9d615c;
  --data-auburn-color-muted: #f2e9e9;
  --data-orange-color-emphasis: #eb670f;
  --data-orange-color-muted: #ffe7d1;
  --data-yellow-color-emphasis: #b88700;
  --data-yellow-color-muted: #ffec9e;
  --data-green-color-emphasis: #30a147;
  --data-green-color-muted: #caf7ca;
  --data-teal-color-emphasis: #179b9b;
  --data-teal-color-muted: #c7f5ef;
  --data-purple-color-emphasis: #894ceb;
  --data-purple-color-muted: #f1e5ff;
  --data-pink-color-emphasis: #ce2c85;
  --data-pink-color-muted: #ffe5f1;
  --data-red-color-emphasis: #df0c24;
  --data-red-color-muted: #ffe2e0;
  --data-gray-color-emphasis: #808fa3;
  --data-gray-color-muted: #e8ecf2;
  --display-blue-bgColor-muted: #d1f0ff;
  --display-blue-bgColor-emphasis: #004db3;
  --display-blue-fgColor: #003d99;
  --display-blue-borderColor-muted: #ade1ff;
  --display-blue-borderColor-emphasis: #005fcc;
  --display-green-bgColor-muted: #caf7ca;
  --display-green-bgColor-emphasis: #285c3b;
  --display-green-fgColor: #254b34;
  --display-green-borderColor-muted: #9ceda0;
  --display-green-borderColor-emphasis: #2b6e3f;
  --display-orange-bgColor-muted: #ffe7d1;
  --display-orange-bgColor-emphasis: #8d3c11;
  --display-orange-fgColor: #70300f;
  --display-orange-borderColor-muted: #fecfaa;
  --display-orange-borderColor-emphasis: #a24610;
  --display-purple-bgColor-muted: #f1e5ff;
  --display-purple-bgColor-emphasis: #6223d7;
  --display-purple-fgColor: #4f21ab;
  --display-purple-borderColor-muted: #e6d2fe;
  --display-purple-borderColor-emphasis: #783ae4;
  --display-plum-bgColor-muted: #f8e5ff;
  --display-plum-bgColor-emphasis: #7d1eb8;
  --display-plum-fgColor: #651d96;
  --display-plum-borderColor-muted: #f0cdfe;
  --display-plum-borderColor-emphasis: #961edc;
  --display-red-bgColor-muted: #ffe2e0;
  --display-red-bgColor-emphasis: #a60c29;
  --display-red-fgColor: #880c27;
  --display-red-borderColor-muted: #fecdcd;
  --display-red-borderColor-emphasis: #c50d28;
  --display-coral-bgColor-muted: #ffe5db;
  --display-coral-bgColor-emphasis: #9b2712;
  --display-coral-fgColor: #7e2011;
  --display-coral-borderColor-muted: #fecebe;
  --display-coral-borderColor-emphasis: #ba2e12;
  --display-yellow-bgColor-muted: #ffec9e;
  --display-yellow-bgColor-emphasis: #704d00;
  --display-yellow-fgColor: #5c3d00;
  --display-yellow-borderColor-muted: #ffd642;
  --display-yellow-borderColor-emphasis: #805900;
  --display-gray-bgColor-muted: #e8ecf2;
  --display-gray-bgColor-emphasis: #4e535a;
  --display-gray-fgColor: #424448;
  --display-gray-borderColor-muted: #d2dae4;
  --display-gray-borderColor-emphasis: #5c6570;
  --display-auburn-bgColor-muted: #f2e9e9;
  --display-auburn-bgColor-emphasis: #744744;
  --display-auburn-fgColor: #5d3937;
  --display-auburn-borderColor-muted: #e6d6d5;
  --display-auburn-borderColor-emphasis: #8a5551;
  --display-brown-bgColor-muted: #eeeae2;
  --display-brown-bgColor-emphasis: #64513a;
  --display-brown-fgColor: #51412f;
  --display-brown-borderColor-muted: #dfd7c8;
  --display-brown-borderColor-emphasis: #755f43;
  --display-lemon-bgColor-muted: #f7eea1;
  --display-lemon-bgColor-emphasis: #654f01;
  --display-lemon-fgColor: #523f00;
  --display-lemon-borderColor-muted: #f0db3d;
  --display-lemon-borderColor-emphasis: #786002;
  --display-olive-bgColor-muted: #f0f0ad;
  --display-olive-bgColor-emphasis: #495a2b;
  --display-olive-fgColor: #3b4927;
  --display-olive-borderColor-muted: #dbe170;
  --display-olive-borderColor-emphasis: #56682c;
  --display-lime-bgColor-muted: #e3f2b5;
  --display-lime-bgColor-emphasis: #3a5b25;
  --display-lime-fgColor: #2f4a21;
  --display-lime-borderColor-muted: #c7e580;
  --display-lime-borderColor-emphasis: #476c28;
  --display-pine-bgColor-muted: #bff8db;
  --display-pine-bgColor-emphasis: #135d41;
  --display-pine-fgColor: #114b36;
  --display-pine-borderColor-muted: #80efb9;
  --display-pine-borderColor-emphasis: #156f4b;
  --display-teal-bgColor-muted: #c7f5ef;
  --display-teal-bgColor-emphasis: #0d5b63;
  --display-teal-fgColor: #0a4852;
  --display-teal-borderColor-muted: #89ebe1;
  --display-teal-borderColor-emphasis: #106e75;
  --display-cyan-bgColor-muted: #bdf4ff;
  --display-cyan-bgColor-emphasis: #00596b;
  --display-cyan-fgColor: #004857;
  --display-cyan-borderColor-muted: #7ae9ff;
  --display-cyan-borderColor-emphasis: #006a80;
  --display-indigo-bgColor-muted: #e5e9ff;
  --display-indigo-bgColor-emphasis: #393cd5;
  --display-indigo-fgColor: #2d2db4;
  --display-indigo-borderColor-muted: #d2d7fe;
  --display-indigo-borderColor-emphasis: #494edf;
  --display-pink-bgColor-muted: #ffe5f1;
  --display-pink-bgColor-emphasis: #8e2e66;
  --display-pink-fgColor: #6e2b53;
  --display-pink-borderColor-muted: #fdc9e2;
  --display-pink-borderColor-emphasis: #b12f79;
  --avatar-bgColor: #ffffff;
  --avatar-borderColor: #010409e6;
  --avatar-shadow: 0px 0px 0px 2px #ffffffcc;
  --avatarStack-fade-bgColor-default: #c8d1da;
  --avatarStack-fade-bgColor-muted: #dae0e7;
  --control-bgColor-rest: #e0e6eb;
  --control-bgColor-hover: #dae0e7;
  --control-bgColor-active: #d1d9e0;
  --control-bgColor-disabled: #e0e6eb;
  --control-bgColor-selected: #e0e6eb;
  --control-fgColor-rest: #25292e;
  --control-fgColor-placeholder: #454c54;
  --control-fgColor-disabled: #59636e;
  --control-borderColor-rest: #454c54;
  --control-borderColor-emphasis: #454c54;
  --control-borderColor-disabled: #59636e1f;
  --control-borderColor-selected: #e0e6eb;
  --control-borderColor-success: #055d20;
  --control-borderColor-danger: #a0111f;
  --control-borderColor-warning: #744500;
  --control-iconColor-rest: #454c54;
  --control-transparent-bgColor-rest: #ffffff00;
  --control-transparent-bgColor-hover: #dae0e7;
  --control-transparent-bgColor-active: #d1d9e0;
  --control-transparent-bgColor-disabled: #e0e6eb;
  --control-transparent-bgColor-selected: #dae0e7;
  --control-transparent-borderColor-rest: #ffffff00;
  --control-transparent-borderColor-hover: #454c54;
  --control-transparent-borderColor-active: #454c54;
  --control-danger-fgColor-rest: #86061d;
  --control-danger-fgColor-hover: #ffffff;
  --control-danger-bgColor-hover: #a0111f;
  --control-danger-bgColor-active: #8c0b1d;
  --control-checked-bgColor-rest: #0349b4;
  --control-checked-bgColor-hover: #0344a8;
  --control-checked-bgColor-active: #033f9d;
  --control-checked-bgColor-disabled: #59636e;
  --control-checked-fgColor-rest: #ffffff;
  --control-checked-fgColor-disabled: #ffffff;
  --control-checked-borderColor-rest: #0349b4;
  --control-checked-borderColor-hover: #0344a8;
  --control-checked-borderColor-active: #033f9d;
  --control-checked-borderColor-disabled: #59636e;
  --controlTrack-bgColor-rest: #dae0e7;
  --controlTrack-bgColor-hover: #d1d9e0;
  --controlTrack-bgColor-active: #c8d1da;
  --controlTrack-bgColor-disabled: #59636e;
  --controlTrack-fgColor-rest: #59636e;
  --controlTrack-fgColor-disabled: #ffffff;
  --controlTrack-borderColor-rest: #454c54;
  --controlTrack-borderColor-disabled: #59636e;
  --controlKnob-bgColor-rest: #ffffff;
  --controlKnob-bgColor-disabled: #e0e6eb;
  --controlKnob-bgColor-checked: #ffffff;
  --controlKnob-borderColor-rest: #454c54;
  --controlKnob-borderColor-disabled: #e0e6eb;
  --controlKnob-borderColor-checked: #0349b4;
  --counter-borderColor: #454c54;
  --counter-bgColor-muted: #e0e6eb;
  --counter-bgColor-emphasis: #454c54;
  --button-default-fgColor-rest: #25292e;
  --button-default-bgColor-rest: #e0e6eb;
  --button-default-bgColor-hover: #dae0e7;
  --button-default-bgColor-active: #d1d9e0;
  --button-default-bgColor-selected: #d1d9e0;
  --button-default-bgColor-disabled: #e0e6eb;
  --button-default-borderColor-rest: #454c54;
  --button-default-borderColor-hover: #454c54;
  --button-default-borderColor-active: #454c54;
  --button-default-borderColor-disabled: #59636e1f;
  --button-default-shadow-resting: 0px 1px 0px 0px #0104090a;
  --button-primary-fgColor-rest: #ffffff;
  --button-primary-fgColor-disabled: #ffffffcc;
  --button-primary-iconColor-rest: #ffffffcc;
  --button-primary-bgColor-rest: #055d20;
  --button-primary-bgColor-hover: #04571e;
  --button-primary-bgColor-active: #03501b;
  --button-primary-bgColor-disabled: #85cb97;
  --button-primary-borderColor-rest: #013d14;
  --button-primary-borderColor-hover: #013d14;
  --button-primary-borderColor-active: #013d14;
  --button-primary-borderColor-disabled: #85cb97;
  --button-primary-shadow-selected: inset 0px 1px 0px 0px #00230b4d;
  --button-invisible-fgColor-rest: #25292e;
  --button-invisible-fgColor-hover: #393f46;
  --button-invisible-fgColor-active: #25292e;
  --button-invisible-fgColor-disabled: #59636e;
  --button-invisible-iconColor-rest: #454c54;
  --button-invisible-iconColor-hover: #393f46;
  --button-invisible-iconColor-disabled: #59636e;
  --button-invisible-bgColor-rest: #ffffff00;
  --button-invisible-bgColor-hover: #dae0e7;
  --button-invisible-bgColor-active: #d1d9e0;
  --button-invisible-bgColor-disabled: #e0e6eb;
  --button-invisible-borderColor-rest: #ffffff00;
  --button-invisible-borderColor-hover: #454c54;
  --button-invisible-borderColor-disabled: #59636e1f;
  --button-outline-fgColor-rest: #023b95;
  --button-outline-fgColor-hover: #ffffff;
  --button-outline-fgColor-active: #ffffff;
  --button-outline-fgColor-disabled: #023b9580;
  --button-outline-bgColor-rest: #e0e6eb;
  --button-outline-bgColor-hover: #0349b4;
  --button-outline-bgColor-active: #033f9d;
  --button-outline-bgColor-disabled: #e0e6eb;
  --button-outline-borderColor-hover: #013d14;
  --button-outline-borderColor-active: #013d14;
  --button-outline-shadow-selected: inset 0px 1px 0px 0px #021a4a33;
  --button-danger-fgColor-rest: #86061d;
  --button-danger-fgColor-hover: #ffffff;
  --button-danger-fgColor-active: #ffffff;
  --button-danger-fgColor-disabled: #86061d80;
  --button-danger-iconColor-rest: #86061d;
  --button-danger-iconColor-hover: #ffffff;
  --button-danger-bgColor-rest: #e0e6eb;
  --button-danger-bgColor-hover: #86061d;
  --button-danger-bgColor-active: #74041a;
  --button-danger-bgColor-disabled: #e0e6eb;
  --button-danger-borderColor-rest: #454c54;
  --button-danger-borderColor-hover: #013d14;
  --button-danger-borderColor-active: #013d14;
  --button-danger-shadow-selected: inset 0px 1px 0px 0px #43001133;
  --button-inactive-fgColor: #454c54;
  --button-inactive-bgColor: #e0e6eb;
  --button-star-iconColor: #d5a824;
  --buttonCounter-default-bgColor-rest: #c8d1da;
  --buttonCounter-invisible-bgColor-rest: #e0e6eb;
  --buttonCounter-primary-bgColor-rest: #00230b33;
  --buttonCounter-outline-bgColor-rest: #0349b41a;
  --buttonCounter-outline-bgColor-hover: #ffffff33;
  --buttonCounter-outline-bgColor-disabled: #0349b40d;
  --buttonCounter-outline-fgColor-rest: #023b95;
  --buttonCounter-outline-fgColor-hover: #ffffff;
  --buttonCounter-outline-fgColor-disabled: #023b9580;
  --buttonCounter-danger-bgColor-hover: #ffffff33;
  --buttonCounter-danger-bgColor-disabled: #a0111f0d;
  --buttonCounter-danger-bgColor-rest: #a0111f1a;
  --buttonCounter-danger-fgColor-rest: #980e1e;
  --buttonCounter-danger-fgColor-hover: #ffffff;
  --buttonCounter-danger-fgColor-disabled: #86061d80;
  --reactionButton-selected-bgColor-rest: #dff7ff;
  --reactionButton-selected-bgColor-hover: #c7e9ff;
  --reactionButton-selected-fgColor-rest: #023b95;
  --reactionButton-selected-fgColor-hover: #023b95;
  --focus-outlineColor: #0349b4;
  --focus-outline: #0349b4 solid 2px;
  --menu-bgColor-active: #ffffff00;
  --overlay-bgColor: #ffffff;
  --overlay-borderColor: #454c54;
  --overlay-backdrop-bgColor: #393f4666;
  --selectMenu-borderColor: #ffffff00;
  --selectMenu-bgColor-active: #9cd7ff;
  --sideNav-bgColor-selected: #ffffff;
  --skeletonLoader-bgColor: #dae0e7;
  --timelineBadge-bgColor: #e6eaef;
  --treeViewItem-leadingVisual-iconColor-rest: #368cf9;
  --underlineNav-borderColor-active: #cd3425;
  --underlineNav-borderColor-hover: #454c54;
  --underlineNav-iconColor-rest: #454c54;
  --selection-bgColor: #0349b433;
  --card-bgColor: #ffffff;
  --label-green-bgColor-rest: #caf7ca;
  --label-green-bgColor-hover: #9ceda0;
  --label-green-bgColor-active: #54d961;
  --label-green-fgColor-rest: #2b6e3f;
  --label-green-fgColor-hover: #285c3b;
  --label-green-fgColor-active: #254b34;
  --label-orange-bgColor-rest: #ffe7d1;
  --label-orange-bgColor-hover: #fecfaa;
  --label-orange-bgColor-active: #fbaf74;
  --label-orange-fgColor-rest: #a24610;
  --label-orange-fgColor-hover: #8d3c11;
  --label-orange-fgColor-active: #70300f;
  --label-purple-bgColor-rest: #f1e5ff;
  --label-purple-bgColor-hover: #e6d2fe;
  --label-purple-bgColor-active: #d1b1fc;
  --label-purple-fgColor-rest: #783ae4;
  --label-purple-fgColor-hover: #6223d7;
  --label-purple-fgColor-active: #4f21ab;
  --label-red-bgColor-rest: #ffe2e0;
  --label-red-bgColor-hover: #fecdcd;
  --label-red-bgColor-active: #fda5a7;
  --label-red-fgColor-rest: #c50d28;
  --label-red-fgColor-hover: #a60c29;
  --label-red-fgColor-active: #880c27;
  --label-yellow-bgColor-rest: #ffec9e;
  --label-yellow-bgColor-hover: #ffd642;
  --label-yellow-bgColor-active: #ebb400;
  --label-yellow-fgColor-rest: #805900;
  --label-yellow-fgColor-hover: #704d00;
  --label-yellow-fgColor-active: #5c3d00;
  --label-gray-bgColor-rest: #e8ecf2;
  --label-gray-bgColor-hover: #d2dae4;
  --label-gray-bgColor-active: #b4c0cf;
  --label-gray-fgColor-rest: #5c6570;
  --label-gray-fgColor-hover: #4e535a;
  --label-gray-fgColor-active: #424448;
  --label-auburn-bgColor-rest: #f2e9e9;
  --label-auburn-bgColor-hover: #e6d6d5;
  --label-auburn-bgColor-active: #d4b7b5;
  --label-auburn-fgColor-rest: #8a5551;
  --label-auburn-fgColor-hover: #744744;
  --label-auburn-fgColor-active: #5d3937;
  --label-brown-bgColor-rest: #eeeae2;
  --label-brown-bgColor-hover: #dfd7c8;
  --label-brown-bgColor-active: #cbbda4;
  --label-brown-fgColor-rest: #755f43;
  --label-brown-fgColor-hover: #64513a;
  --label-brown-fgColor-active: #51412f;
  --label-lemon-bgColor-rest: #f7eea1;
  --label-lemon-bgColor-hover: #f0db3d;
  --label-lemon-bgColor-active: #d8bd0e;
  --label-lemon-fgColor-rest: #786002;
  --label-lemon-fgColor-hover: #654f01;
  --label-lemon-fgColor-active: #523f00;
  --label-olive-bgColor-rest: #f0f0ad;
  --label-olive-bgColor-hover: #dbe170;
  --label-olive-bgColor-active: #b9c832;
  --label-olive-fgColor-rest: #56682c;
  --label-olive-fgColor-hover: #495a2b;
  --label-olive-fgColor-active: #3b4927;
  --label-lime-bgColor-rest: #e3f2b5;
  --label-lime-bgColor-hover: #c7e580;
  --label-lime-bgColor-active: #9bd039;
  --label-lime-fgColor-rest: #476c28;
  --label-lime-fgColor-hover: #3a5b25;
  --label-lime-fgColor-active: #2f4a21;
  --label-pine-bgColor-rest: #bff8db;
  --label-pine-bgColor-hover: #80efb9;
  --label-pine-bgColor-active: #1dd781;
  --label-pine-fgColor-rest: #156f4b;
  --label-pine-fgColor-hover: #135d41;
  --label-pine-fgColor-active: #114b36;
  --label-teal-bgColor-rest: #c7f5ef;
  --label-teal-bgColor-hover: #89ebe1;
  --label-teal-bgColor-active: #22d3c7;
  --label-teal-fgColor-rest: #106e75;
  --label-teal-fgColor-hover: #0d5b63;
  --label-teal-fgColor-active: #0a4852;
  --label-cyan-bgColor-rest: #bdf4ff;
  --label-cyan-bgColor-hover: #7ae9ff;
  --label-cyan-bgColor-active: #00d0fa;
  --label-cyan-fgColor-rest: #006a80;
  --label-cyan-fgColor-hover: #00596b;
  --label-cyan-fgColor-active: #004857;
  --label-indigo-bgColor-rest: #e5e9ff;
  --label-indigo-bgColor-hover: #d2d7fe;
  --label-indigo-bgColor-active: #b1b9fb;
  --label-indigo-fgColor-rest: #494edf;
  --label-indigo-fgColor-hover: #393cd5;
  --label-indigo-fgColor-active: #2d2db4;
  --label-blue-bgColor-rest: #d1f0ff;
  --label-blue-bgColor-hover: #ade1ff;
  --label-blue-bgColor-active: #75c8ff;
  --label-blue-fgColor-rest: #005fcc;
  --label-blue-fgColor-hover: #004db3;
  --label-blue-fgColor-active: #003d99;
  --label-plum-bgColor-rest: #f8e5ff;
  --label-plum-bgColor-hover: #f0cdfe;
  --label-plum-bgColor-active: #e2a7fb;
  --label-plum-fgColor-rest: #961edc;
  --label-plum-fgColor-hover: #7d1eb8;
  --label-plum-fgColor-active: #651d96;
  --label-pink-bgColor-rest: #ffe5f1;
  --label-pink-bgColor-hover: #fdc9e2;
  --label-pink-bgColor-active: #f8a5cf;
  --label-pink-fgColor-rest: #b12f79;
  --label-pink-fgColor-hover: #8e2e66;
  --label-pink-fgColor-active: #6e2b53;
  --label-coral-bgColor-rest: #ffe5db;
  --label-coral-bgColor-hover: #fecebe;
  --label-coral-bgColor-active: #fcab92;
  --label-coral-fgColor-rest: #ba2e12;
  --label-coral-fgColor-hover: #9b2712;
  --label-coral-fgColor-active: #7e2011;
  --tooltip-bgColor: #25292e;
  --tooltip-fgColor: #ffffff;
  --fgColor-default: #010409;
  --fgColor-muted: #454c54;
  --fgColor-onEmphasis: #ffffff;
  --fgColor-onInverse: #ffffff;
  --fgColor-white: #ffffff;
  --fgColor-black: #010409;
  --fgColor-disabled: #59636e;
  --fgColor-link: #023b95;
  --fgColor-neutral: #393f46;
  --fgColor-accent: #023b95;
  --fgColor-success: #024c1a;
  --fgColor-open: #024c1a;
  --fgColor-attention: #603700;
  --fgColor-severe: #702c00;
  --fgColor-danger: #86061d;
  --fgColor-closed: #86061d;
  --fgColor-done: #512598;
  --fgColor-upsell: #512598;
  --fgColor-sponsors: #7d0c57;
  --bgColor-default: #ffffff;
  --bgColor-muted: #e6eaef;
  --bgColor-inset: #eff2f5;
  --bgColor-emphasis: #25292e;
  --bgColor-inverse: #25292e;
  --bgColor-white: #ffffff;
  --bgColor-black: #010409;
  --bgColor-disabled: #e0e6eb;
  --bgColor-transparent: #ffffff00;
  --bgColor-neutral-muted: #e0e6eb;
  --bgColor-neutral-emphasis: #454c54;
  --bgColor-accent-muted: #dff7ff;
  --bgColor-accent-emphasis: #0349b4;
  --bgColor-success-muted: #d2fedb;
  --bgColor-success-emphasis: #055d20;
  --bgColor-open-muted: #d2fedb;
  --bgColor-open-emphasis: #055d20;
  --bgColor-attention-muted: #fcf7be;
  --bgColor-attention-emphasis: #744500;
  --bgColor-severe-muted: #fff2d5;
  --bgColor-severe-emphasis: #873800;
  --bgColor-danger-muted: #fff0ee;
  --bgColor-danger-emphasis: #a0111f;
  --bgColor-closed-muted: #fff0ee;
  --bgColor-closed-emphasis: #a0111f;
  --bgColor-done-muted: #faf0fe;
  --bgColor-done-emphasis: #622cbc;
  --bgColor-upsell-muted: #faf0fe;
  --bgColor-upsell-emphasis: #622cbc;
  --bgColor-sponsors-muted: #feeff7;
  --bgColor-sponsors-emphasis: #971368;
  --borderColor-default: #454c54;
  --borderColor-muted: #454c54;
  --borderColor-emphasis: #454c54;
  --borderColor-disabled: #59636e1f;
  --borderColor-transparent: #ffffff00;
  --borderColor-translucent: #59636e;
  --borderColor-neutral-muted: #454c54;
  --borderColor-neutral-emphasis: #59636e;
  --borderColor-accent-muted: #368cf9;
  --borderColor-accent-emphasis: #0349b4;
  --borderColor-success-muted: #26a148;
  --borderColor-success-emphasis: #055d20;
  --borderColor-open-muted: #26a148;
  --borderColor-open-emphasis: #055d20;
  --borderColor-attention-muted: #b58407;
  --borderColor-attention-emphasis: #744500;
  --borderColor-severe-muted: #dc6d1a;
  --borderColor-severe-emphasis: #873800;
  --borderColor-danger-muted: #ee5a5d;
  --borderColor-danger-emphasis: #a0111f;
  --borderColor-closed-muted: #ee5a5d;
  --borderColor-closed-emphasis: #a0111f;
  --borderColor-done-muted: #a371f7;
  --borderColor-done-emphasis: #622cbc;
  --borderColor-upsell-muted: #a371f7;
  --borderColor-upsell-emphasis: #622cbc;
  --borderColor-sponsors-muted: #ed4baf;
  --borderColor-sponsors-emphasis: #971368;
  --color-ansi-black: #010409;
  --color-ansi-black-bright: #393f46;
  --color-ansi-white: #59636e;
  --color-ansi-white-bright: #818b98;
  --color-ansi-gray: #59636e;
  --color-ansi-red: #a0111f;
  --color-ansi-red-bright: #86061d;
  --color-ansi-green: #024c1a;
  --color-ansi-green-bright: #055d20;
  --color-ansi-yellow: #3f2200;
  --color-ansi-yellow-bright: #4e2c00;
  --color-ansi-blue: #0349b4;
  --color-ansi-blue-bright: #1168e3;
  --color-ansi-magenta: #622cbc;
  --color-ansi-magenta-bright: #844ae7;
  --color-ansi-cyan: #1b7c83;
  --color-ansi-cyan-bright: #3192aa;
  --color-prettylights-syntax-comment: #59636e;
  --color-prettylights-syntax-constant: #023b95;
  --color-prettylights-syntax-constant-other-reference-link: #032563;
  --color-prettylights-syntax-entity: #512598;
  --color-prettylights-syntax-storage-modifier-import: #010409;
  --color-prettylights-syntax-entity-tag: #023b95;
  --color-prettylights-syntax-keyword: #a0111f;
  --color-prettylights-syntax-string: #032563;
  --color-prettylights-syntax-variable: #702c00;
  --color-prettylights-syntax-brackethighlighter-unmatched: #6e011a;
  --color-prettylights-syntax-brackethighlighter-angle: #59636e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #6e011a;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #a0111f;
  --color-prettylights-syntax-string-regexp: #024c1a;
  --color-prettylights-syntax-markup-list: #2e1800;
  --color-prettylights-syntax-markup-heading: #023b95;
  --color-prettylights-syntax-markup-italic: #010409;
  --color-prettylights-syntax-markup-bold: #010409;
  --color-prettylights-syntax-markup-deleted-text: #6e011a;
  --color-prettylights-syntax-markup-deleted-bg: #fff0ee;
  --color-prettylights-syntax-markup-inserted-text: #024c1a;
  --color-prettylights-syntax-markup-inserted-bg: #d2fedb;
  --color-prettylights-syntax-markup-changed-text: #702c00;
  --color-prettylights-syntax-markup-changed-bg: #ffc67b;
  --color-prettylights-syntax-markup-ignored-text: #d1d9e0;
  --color-prettylights-syntax-markup-ignored-bg: #023b95;
  --color-prettylights-syntax-meta-diff-range: #622cbc;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #818b98;
  --shadow-inset: inset 0px 1px 0px 0px #0104090a;
  --shadow-resting-xsmall: 0px 1px 0px 0px #0104091a;
  --shadow-resting-small: 0px 1px 0px 0px #0104090a;
  --shadow-resting-medium: 0px 3px 6px 0px #25292e1f;
  --shadow-floating-small: 0px 0px 0px 1px #454c5480, 0px 6px 12px -3px #25292e0a, 0px 6px 18px 0px #25292e1f;
  --shadow-floating-medium: 0px 0px 0px 1px #454c54, 0px 8px 16px -4px #25292e14, 0px 4px 32px -4px #25292e14, 0px 24px 48px -12px #25292e14, 0px 48px 96px -24px #25292e14;
  --shadow-floating-large: 0px 0px 0px 1px #454c54, 0px 40px 80px 0px #25292e3d;
  --shadow-floating-xlarge: 0px 0px 0px 1px #454c54, 0px 56px 112px 0px #25292e52;
  --shadow-floating-legacy: 0px 6px 12px -3px #25292e0a, 0px 6px 18px 0px #25292e1f;
}
@media (prefers-color-scheme: dark) {
  [data-color-mode="auto"][data-dark-theme="light_high_contrast"],
  [data-color-mode="auto"][data-dark-theme="light_high_contrast"] ::backdrop {
    --topicTag-borderColor: #0349b4;
    --highlight-neutral-bgColor: #fcf7be;
    --page-header-bgColor: #e6eaef;
    --diffBlob-additionLine-fgColor: #010409;
    --diffBlob-additionLine-bgColor: #d2fedb;
    --diffBlob-additionWord-fgColor: #ffffff;
    --diffBlob-additionWord-bgColor: #055d20;
    --diffBlob-additionNum-fgColor: #010409;
    --diffBlob-additionNum-bgColor: #82e596;
    --diffBlob-deletionLine-fgColor: #010409;
    --diffBlob-deletionLine-bgColor: #fff0ee;
    --diffBlob-deletionWord-fgColor: #ffffff;
    --diffBlob-deletionWord-bgColor: #a0111f;
    --diffBlob-deletionNum-fgColor: #010409;
    --diffBlob-deletionNum-bgColor: #ffc1bc;
    --diffBlob-hunkLine-bgColor: #dff7ff;
    --diffBlob-hunkLine-fgColor: #454c54;
    --diffBlob-hunkNum-fgColor-rest: #010409;
    --diffBlob-hunkNum-fgColor-hover: #ffffff;
    --diffBlob-hunkNum-bgColor-rest: #9cd7ff;
    --diffBlob-hunkNum-bgColor-hover: #0349b4;
    --diffBlob-emptyNum-bgColor: #e6eaef;
    --diffBlob-emptyLine-bgColor: #e6eaef;
    --diffBlob-expander-iconColor: #010409;
    --codeMirror-fgColor: #010409;
    --codeMirror-bgColor: #ffffff;
    --codeMirror-gutters-bgColor: #ffffff;
    --codeMirror-gutterMarker-fgColor-default: #ffffff;
    --codeMirror-gutterMarker-fgColor-muted: #454c54;
    --codeMirror-lineNumber-fgColor: #454c54;
    --codeMirror-cursor-fgColor: #010409;
    --codeMirror-selection-bgColor: #368cf9;
    --codeMirror-activeline-bgColor: #e0e6eb;
    --codeMirror-matchingBracket-fgColor: #010409;
    --codeMirror-lines-bgColor: #ffffff;
    --codeMirror-syntax-fgColor-comment: #010409;
    --codeMirror-syntax-fgColor-constant: #023b95;
    --codeMirror-syntax-fgColor-entity: #622cbc;
    --codeMirror-syntax-fgColor-keyword: #a0111f;
    --codeMirror-syntax-fgColor-storage: #a0111f;
    --codeMirror-syntax-fgColor-string: #032563;
    --codeMirror-syntax-fgColor-support: #023b95;
    --codeMirror-syntax-fgColor-variable: #702c00;
    --header-fgColor-default: #ffffffb3;
    --header-fgColor-logo: #ffffff;
    --header-bgColor: #25292e;
    --header-borderColor-divider: #c8d1da;
    --headerSearch-bgColor: #25292e;
    --headerSearch-borderColor: #818b98;
    --data-blue-color-emphasis: #006edb;
    --data-blue-color-muted: #d1f0ff;
    --data-auburn-color-emphasis: #9d615c;
    --data-auburn-color-muted: #f2e9e9;
    --data-orange-color-emphasis: #eb670f;
    --data-orange-color-muted: #ffe7d1;
    --data-yellow-color-emphasis: #b88700;
    --data-yellow-color-muted: #ffec9e;
    --data-green-color-emphasis: #30a147;
    --data-green-color-muted: #caf7ca;
    --data-teal-color-emphasis: #179b9b;
    --data-teal-color-muted: #c7f5ef;
    --data-purple-color-emphasis: #894ceb;
    --data-purple-color-muted: #f1e5ff;
    --data-pink-color-emphasis: #ce2c85;
    --data-pink-color-muted: #ffe5f1;
    --data-red-color-emphasis: #df0c24;
    --data-red-color-muted: #ffe2e0;
    --data-gray-color-emphasis: #808fa3;
    --data-gray-color-muted: #e8ecf2;
    --display-blue-bgColor-muted: #d1f0ff;
    --display-blue-bgColor-emphasis: #004db3;
    --display-blue-fgColor: #003d99;
    --display-blue-borderColor-muted: #ade1ff;
    --display-blue-borderColor-emphasis: #005fcc;
    --display-green-bgColor-muted: #caf7ca;
    --display-green-bgColor-emphasis: #285c3b;
    --display-green-fgColor: #254b34;
    --display-green-borderColor-muted: #9ceda0;
    --display-green-borderColor-emphasis: #2b6e3f;
    --display-orange-bgColor-muted: #ffe7d1;
    --display-orange-bgColor-emphasis: #8d3c11;
    --display-orange-fgColor: #70300f;
    --display-orange-borderColor-muted: #fecfaa;
    --display-orange-borderColor-emphasis: #a24610;
    --display-purple-bgColor-muted: #f1e5ff;
    --display-purple-bgColor-emphasis: #6223d7;
    --display-purple-fgColor: #4f21ab;
    --display-purple-borderColor-muted: #e6d2fe;
    --display-purple-borderColor-emphasis: #783ae4;
    --display-plum-bgColor-muted: #f8e5ff;
    --display-plum-bgColor-emphasis: #7d1eb8;
    --display-plum-fgColor: #651d96;
    --display-plum-borderColor-muted: #f0cdfe;
    --display-plum-borderColor-emphasis: #961edc;
    --display-red-bgColor-muted: #ffe2e0;
    --display-red-bgColor-emphasis: #a60c29;
    --display-red-fgColor: #880c27;
    --display-red-borderColor-muted: #fecdcd;
    --display-red-borderColor-emphasis: #c50d28;
    --display-coral-bgColor-muted: #ffe5db;
    --display-coral-bgColor-emphasis: #9b2712;
    --display-coral-fgColor: #7e2011;
    --display-coral-borderColor-muted: #fecebe;
    --display-coral-borderColor-emphasis: #ba2e12;
    --display-yellow-bgColor-muted: #ffec9e;
    --display-yellow-bgColor-emphasis: #704d00;
    --display-yellow-fgColor: #5c3d00;
    --display-yellow-borderColor-muted: #ffd642;
    --display-yellow-borderColor-emphasis: #805900;
    --display-gray-bgColor-muted: #e8ecf2;
    --display-gray-bgColor-emphasis: #4e535a;
    --display-gray-fgColor: #424448;
    --display-gray-borderColor-muted: #d2dae4;
    --display-gray-borderColor-emphasis: #5c6570;
    --display-auburn-bgColor-muted: #f2e9e9;
    --display-auburn-bgColor-emphasis: #744744;
    --display-auburn-fgColor: #5d3937;
    --display-auburn-borderColor-muted: #e6d6d5;
    --display-auburn-borderColor-emphasis: #8a5551;
    --display-brown-bgColor-muted: #eeeae2;
    --display-brown-bgColor-emphasis: #64513a;
    --display-brown-fgColor: #51412f;
    --display-brown-borderColor-muted: #dfd7c8;
    --display-brown-borderColor-emphasis: #755f43;
    --display-lemon-bgColor-muted: #f7eea1;
    --display-lemon-bgColor-emphasis: #654f01;
    --display-lemon-fgColor: #523f00;
    --display-lemon-borderColor-muted: #f0db3d;
    --display-lemon-borderColor-emphasis: #786002;
    --display-olive-bgColor-muted: #f0f0ad;
    --display-olive-bgColor-emphasis: #495a2b;
    --display-olive-fgColor: #3b4927;
    --display-olive-borderColor-muted: #dbe170;
    --display-olive-borderColor-emphasis: #56682c;
    --display-lime-bgColor-muted: #e3f2b5;
    --display-lime-bgColor-emphasis: #3a5b25;
    --display-lime-fgColor: #2f4a21;
    --display-lime-borderColor-muted: #c7e580;
    --display-lime-borderColor-emphasis: #476c28;
    --display-pine-bgColor-muted: #bff8db;
    --display-pine-bgColor-emphasis: #135d41;
    --display-pine-fgColor: #114b36;
    --display-pine-borderColor-muted: #80efb9;
    --display-pine-borderColor-emphasis: #156f4b;
    --display-teal-bgColor-muted: #c7f5ef;
    --display-teal-bgColor-emphasis: #0d5b63;
    --display-teal-fgColor: #0a4852;
    --display-teal-borderColor-muted: #89ebe1;
    --display-teal-borderColor-emphasis: #106e75;
    --display-cyan-bgColor-muted: #bdf4ff;
    --display-cyan-bgColor-emphasis: #00596b;
    --display-cyan-fgColor: #004857;
    --display-cyan-borderColor-muted: #7ae9ff;
    --display-cyan-borderColor-emphasis: #006a80;
    --display-indigo-bgColor-muted: #e5e9ff;
    --display-indigo-bgColor-emphasis: #393cd5;
    --display-indigo-fgColor: #2d2db4;
    --display-indigo-borderColor-muted: #d2d7fe;
    --display-indigo-borderColor-emphasis: #494edf;
    --display-pink-bgColor-muted: #ffe5f1;
    --display-pink-bgColor-emphasis: #8e2e66;
    --display-pink-fgColor: #6e2b53;
    --display-pink-borderColor-muted: #fdc9e2;
    --display-pink-borderColor-emphasis: #b12f79;
    --avatar-bgColor: #ffffff;
    --avatar-borderColor: #010409e6;
    --avatar-shadow: 0px 0px 0px 2px #ffffffcc;
    --avatarStack-fade-bgColor-default: #c8d1da;
    --avatarStack-fade-bgColor-muted: #dae0e7;
    --control-bgColor-rest: #e0e6eb;
    --control-bgColor-hover: #dae0e7;
    --control-bgColor-active: #d1d9e0;
    --control-bgColor-disabled: #e0e6eb;
    --control-bgColor-selected: #e0e6eb;
    --control-fgColor-rest: #25292e;
    --control-fgColor-placeholder: #454c54;
    --control-fgColor-disabled: #59636e;
    --control-borderColor-rest: #454c54;
    --control-borderColor-emphasis: #454c54;
    --control-borderColor-disabled: #59636e1f;
    --control-borderColor-selected: #e0e6eb;
    --control-borderColor-success: #055d20;
    --control-borderColor-danger: #a0111f;
    --control-borderColor-warning: #744500;
    --control-iconColor-rest: #454c54;
    --control-transparent-bgColor-rest: #ffffff00;
    --control-transparent-bgColor-hover: #dae0e7;
    --control-transparent-bgColor-active: #d1d9e0;
    --control-transparent-bgColor-disabled: #e0e6eb;
    --control-transparent-bgColor-selected: #dae0e7;
    --control-transparent-borderColor-rest: #ffffff00;
    --control-transparent-borderColor-hover: #454c54;
    --control-transparent-borderColor-active: #454c54;
    --control-danger-fgColor-rest: #86061d;
    --control-danger-fgColor-hover: #ffffff;
    --control-danger-bgColor-hover: #a0111f;
    --control-danger-bgColor-active: #8c0b1d;
    --control-checked-bgColor-rest: #0349b4;
    --control-checked-bgColor-hover: #0344a8;
    --control-checked-bgColor-active: #033f9d;
    --control-checked-bgColor-disabled: #59636e;
    --control-checked-fgColor-rest: #ffffff;
    --control-checked-fgColor-disabled: #ffffff;
    --control-checked-borderColor-rest: #0349b4;
    --control-checked-borderColor-hover: #0344a8;
    --control-checked-borderColor-active: #033f9d;
    --control-checked-borderColor-disabled: #59636e;
    --controlTrack-bgColor-rest: #dae0e7;
    --controlTrack-bgColor-hover: #d1d9e0;
    --controlTrack-bgColor-active: #c8d1da;
    --controlTrack-bgColor-disabled: #59636e;
    --controlTrack-fgColor-rest: #59636e;
    --controlTrack-fgColor-disabled: #ffffff;
    --controlTrack-borderColor-rest: #454c54;
    --controlTrack-borderColor-disabled: #59636e;
    --controlKnob-bgColor-rest: #ffffff;
    --controlKnob-bgColor-disabled: #e0e6eb;
    --controlKnob-bgColor-checked: #ffffff;
    --controlKnob-borderColor-rest: #454c54;
    --controlKnob-borderColor-disabled: #e0e6eb;
    --controlKnob-borderColor-checked: #0349b4;
    --counter-borderColor: #454c54;
    --counter-bgColor-muted: #e0e6eb;
    --counter-bgColor-emphasis: #454c54;
    --button-default-fgColor-rest: #25292e;
    --button-default-bgColor-rest: #e0e6eb;
    --button-default-bgColor-hover: #dae0e7;
    --button-default-bgColor-active: #d1d9e0;
    --button-default-bgColor-selected: #d1d9e0;
    --button-default-bgColor-disabled: #e0e6eb;
    --button-default-borderColor-rest: #454c54;
    --button-default-borderColor-hover: #454c54;
    --button-default-borderColor-active: #454c54;
    --button-default-borderColor-disabled: #59636e1f;
    --button-default-shadow-resting: 0px 1px 0px 0px #0104090a;
    --button-primary-fgColor-rest: #ffffff;
    --button-primary-fgColor-disabled: #ffffffcc;
    --button-primary-iconColor-rest: #ffffffcc;
    --button-primary-bgColor-rest: #055d20;
    --button-primary-bgColor-hover: #04571e;
    --button-primary-bgColor-active: #03501b;
    --button-primary-bgColor-disabled: #85cb97;
    --button-primary-borderColor-rest: #013d14;
    --button-primary-borderColor-hover: #013d14;
    --button-primary-borderColor-active: #013d14;
    --button-primary-borderColor-disabled: #85cb97;
    --button-primary-shadow-selected: inset 0px 1px 0px 0px #00230b4d;
    --button-invisible-fgColor-rest: #25292e;
    --button-invisible-fgColor-hover: #393f46;
    --button-invisible-fgColor-active: #25292e;
    --button-invisible-fgColor-disabled: #59636e;
    --button-invisible-iconColor-rest: #454c54;
    --button-invisible-iconColor-hover: #393f46;
    --button-invisible-iconColor-disabled: #59636e;
    --button-invisible-bgColor-rest: #ffffff00;
    --button-invisible-bgColor-hover: #dae0e7;
    --button-invisible-bgColor-active: #d1d9e0;
    --button-invisible-bgColor-disabled: #e0e6eb;
    --button-invisible-borderColor-rest: #ffffff00;
    --button-invisible-borderColor-hover: #454c54;
    --button-invisible-borderColor-disabled: #59636e1f;
    --button-outline-fgColor-rest: #023b95;
    --button-outline-fgColor-hover: #ffffff;
    --button-outline-fgColor-active: #ffffff;
    --button-outline-fgColor-disabled: #023b9580;
    --button-outline-bgColor-rest: #e0e6eb;
    --button-outline-bgColor-hover: #0349b4;
    --button-outline-bgColor-active: #033f9d;
    --button-outline-bgColor-disabled: #e0e6eb;
    --button-outline-borderColor-hover: #013d14;
    --button-outline-borderColor-active: #013d14;
    --button-outline-shadow-selected: inset 0px 1px 0px 0px #021a4a33;
    --button-danger-fgColor-rest: #86061d;
    --button-danger-fgColor-hover: #ffffff;
    --button-danger-fgColor-active: #ffffff;
    --button-danger-fgColor-disabled: #86061d80;
    --button-danger-iconColor-rest: #86061d;
    --button-danger-iconColor-hover: #ffffff;
    --button-danger-bgColor-rest: #e0e6eb;
    --button-danger-bgColor-hover: #86061d;
    --button-danger-bgColor-active: #74041a;
    --button-danger-bgColor-disabled: #e0e6eb;
    --button-danger-borderColor-rest: #454c54;
    --button-danger-borderColor-hover: #013d14;
    --button-danger-borderColor-active: #013d14;
    --button-danger-shadow-selected: inset 0px 1px 0px 0px #43001133;
    --button-inactive-fgColor: #454c54;
    --button-inactive-bgColor: #e0e6eb;
    --button-star-iconColor: #d5a824;
    --buttonCounter-default-bgColor-rest: #c8d1da;
    --buttonCounter-invisible-bgColor-rest: #e0e6eb;
    --buttonCounter-primary-bgColor-rest: #00230b33;
    --buttonCounter-outline-bgColor-rest: #0349b41a;
    --buttonCounter-outline-bgColor-hover: #ffffff33;
    --buttonCounter-outline-bgColor-disabled: #0349b40d;
    --buttonCounter-outline-fgColor-rest: #023b95;
    --buttonCounter-outline-fgColor-hover: #ffffff;
    --buttonCounter-outline-fgColor-disabled: #023b9580;
    --buttonCounter-danger-bgColor-hover: #ffffff33;
    --buttonCounter-danger-bgColor-disabled: #a0111f0d;
    --buttonCounter-danger-bgColor-rest: #a0111f1a;
    --buttonCounter-danger-fgColor-rest: #980e1e;
    --buttonCounter-danger-fgColor-hover: #ffffff;
    --buttonCounter-danger-fgColor-disabled: #86061d80;
    --reactionButton-selected-bgColor-rest: #dff7ff;
    --reactionButton-selected-bgColor-hover: #c7e9ff;
    --reactionButton-selected-fgColor-rest: #023b95;
    --reactionButton-selected-fgColor-hover: #023b95;
    --focus-outlineColor: #0349b4;
    --focus-outline: #0349b4 solid 2px;
    --menu-bgColor-active: #ffffff00;
    --overlay-bgColor: #ffffff;
    --overlay-borderColor: #454c54;
    --overlay-backdrop-bgColor: #393f4666;
    --selectMenu-borderColor: #ffffff00;
    --selectMenu-bgColor-active: #9cd7ff;
    --sideNav-bgColor-selected: #ffffff;
    --skeletonLoader-bgColor: #dae0e7;
    --timelineBadge-bgColor: #e6eaef;
    --treeViewItem-leadingVisual-iconColor-rest: #368cf9;
    --underlineNav-borderColor-active: #cd3425;
    --underlineNav-borderColor-hover: #454c54;
    --underlineNav-iconColor-rest: #454c54;
    --selection-bgColor: #0349b433;
    --card-bgColor: #ffffff;
    --label-green-bgColor-rest: #caf7ca;
    --label-green-bgColor-hover: #9ceda0;
    --label-green-bgColor-active: #54d961;
    --label-green-fgColor-rest: #2b6e3f;
    --label-green-fgColor-hover: #285c3b;
    --label-green-fgColor-active: #254b34;
    --label-orange-bgColor-rest: #ffe7d1;
    --label-orange-bgColor-hover: #fecfaa;
    --label-orange-bgColor-active: #fbaf74;
    --label-orange-fgColor-rest: #a24610;
    --label-orange-fgColor-hover: #8d3c11;
    --label-orange-fgColor-active: #70300f;
    --label-purple-bgColor-rest: #f1e5ff;
    --label-purple-bgColor-hover: #e6d2fe;
    --label-purple-bgColor-active: #d1b1fc;
    --label-purple-fgColor-rest: #783ae4;
    --label-purple-fgColor-hover: #6223d7;
    --label-purple-fgColor-active: #4f21ab;
    --label-red-bgColor-rest: #ffe2e0;
    --label-red-bgColor-hover: #fecdcd;
    --label-red-bgColor-active: #fda5a7;
    --label-red-fgColor-rest: #c50d28;
    --label-red-fgColor-hover: #a60c29;
    --label-red-fgColor-active: #880c27;
    --label-yellow-bgColor-rest: #ffec9e;
    --label-yellow-bgColor-hover: #ffd642;
    --label-yellow-bgColor-active: #ebb400;
    --label-yellow-fgColor-rest: #805900;
    --label-yellow-fgColor-hover: #704d00;
    --label-yellow-fgColor-active: #5c3d00;
    --label-gray-bgColor-rest: #e8ecf2;
    --label-gray-bgColor-hover: #d2dae4;
    --label-gray-bgColor-active: #b4c0cf;
    --label-gray-fgColor-rest: #5c6570;
    --label-gray-fgColor-hover: #4e535a;
    --label-gray-fgColor-active: #424448;
    --label-auburn-bgColor-rest: #f2e9e9;
    --label-auburn-bgColor-hover: #e6d6d5;
    --label-auburn-bgColor-active: #d4b7b5;
    --label-auburn-fgColor-rest: #8a5551;
    --label-auburn-fgColor-hover: #744744;
    --label-auburn-fgColor-active: #5d3937;
    --label-brown-bgColor-rest: #eeeae2;
    --label-brown-bgColor-hover: #dfd7c8;
    --label-brown-bgColor-active: #cbbda4;
    --label-brown-fgColor-rest: #755f43;
    --label-brown-fgColor-hover: #64513a;
    --label-brown-fgColor-active: #51412f;
    --label-lemon-bgColor-rest: #f7eea1;
    --label-lemon-bgColor-hover: #f0db3d;
    --label-lemon-bgColor-active: #d8bd0e;
    --label-lemon-fgColor-rest: #786002;
    --label-lemon-fgColor-hover: #654f01;
    --label-lemon-fgColor-active: #523f00;
    --label-olive-bgColor-rest: #f0f0ad;
    --label-olive-bgColor-hover: #dbe170;
    --label-olive-bgColor-active: #b9c832;
    --label-olive-fgColor-rest: #56682c;
    --label-olive-fgColor-hover: #495a2b;
    --label-olive-fgColor-active: #3b4927;
    --label-lime-bgColor-rest: #e3f2b5;
    --label-lime-bgColor-hover: #c7e580;
    --label-lime-bgColor-active: #9bd039;
    --label-lime-fgColor-rest: #476c28;
    --label-lime-fgColor-hover: #3a5b25;
    --label-lime-fgColor-active: #2f4a21;
    --label-pine-bgColor-rest: #bff8db;
    --label-pine-bgColor-hover: #80efb9;
    --label-pine-bgColor-active: #1dd781;
    --label-pine-fgColor-rest: #156f4b;
    --label-pine-fgColor-hover: #135d41;
    --label-pine-fgColor-active: #114b36;
    --label-teal-bgColor-rest: #c7f5ef;
    --label-teal-bgColor-hover: #89ebe1;
    --label-teal-bgColor-active: #22d3c7;
    --label-teal-fgColor-rest: #106e75;
    --label-teal-fgColor-hover: #0d5b63;
    --label-teal-fgColor-active: #0a4852;
    --label-cyan-bgColor-rest: #bdf4ff;
    --label-cyan-bgColor-hover: #7ae9ff;
    --label-cyan-bgColor-active: #00d0fa;
    --label-cyan-fgColor-rest: #006a80;
    --label-cyan-fgColor-hover: #00596b;
    --label-cyan-fgColor-active: #004857;
    --label-indigo-bgColor-rest: #e5e9ff;
    --label-indigo-bgColor-hover: #d2d7fe;
    --label-indigo-bgColor-active: #b1b9fb;
    --label-indigo-fgColor-rest: #494edf;
    --label-indigo-fgColor-hover: #393cd5;
    --label-indigo-fgColor-active: #2d2db4;
    --label-blue-bgColor-rest: #d1f0ff;
    --label-blue-bgColor-hover: #ade1ff;
    --label-blue-bgColor-active: #75c8ff;
    --label-blue-fgColor-rest: #005fcc;
    --label-blue-fgColor-hover: #004db3;
    --label-blue-fgColor-active: #003d99;
    --label-plum-bgColor-rest: #f8e5ff;
    --label-plum-bgColor-hover: #f0cdfe;
    --label-plum-bgColor-active: #e2a7fb;
    --label-plum-fgColor-rest: #961edc;
    --label-plum-fgColor-hover: #7d1eb8;
    --label-plum-fgColor-active: #651d96;
    --label-pink-bgColor-rest: #ffe5f1;
    --label-pink-bgColor-hover: #fdc9e2;
    --label-pink-bgColor-active: #f8a5cf;
    --label-pink-fgColor-rest: #b12f79;
    --label-pink-fgColor-hover: #8e2e66;
    --label-pink-fgColor-active: #6e2b53;
    --label-coral-bgColor-rest: #ffe5db;
    --label-coral-bgColor-hover: #fecebe;
    --label-coral-bgColor-active: #fcab92;
    --label-coral-fgColor-rest: #ba2e12;
    --label-coral-fgColor-hover: #9b2712;
    --label-coral-fgColor-active: #7e2011;
    --tooltip-bgColor: #25292e;
    --tooltip-fgColor: #ffffff;
    --fgColor-default: #010409;
    --fgColor-muted: #454c54;
    --fgColor-onEmphasis: #ffffff;
    --fgColor-onInverse: #ffffff;
    --fgColor-white: #ffffff;
    --fgColor-black: #010409;
    --fgColor-disabled: #59636e;
    --fgColor-link: #023b95;
    --fgColor-neutral: #393f46;
    --fgColor-accent: #023b95;
    --fgColor-success: #024c1a;
    --fgColor-open: #024c1a;
    --fgColor-attention: #603700;
    --fgColor-severe: #702c00;
    --fgColor-danger: #86061d;
    --fgColor-closed: #86061d;
    --fgColor-done: #512598;
    --fgColor-upsell: #512598;
    --fgColor-sponsors: #7d0c57;
    --bgColor-default: #ffffff;
    --bgColor-muted: #e6eaef;
    --bgColor-inset: #eff2f5;
    --bgColor-emphasis: #25292e;
    --bgColor-inverse: #25292e;
    --bgColor-white: #ffffff;
    --bgColor-black: #010409;
    --bgColor-disabled: #e0e6eb;
    --bgColor-transparent: #ffffff00;
    --bgColor-neutral-muted: #e0e6eb;
    --bgColor-neutral-emphasis: #454c54;
    --bgColor-accent-muted: #dff7ff;
    --bgColor-accent-emphasis: #0349b4;
    --bgColor-success-muted: #d2fedb;
    --bgColor-success-emphasis: #055d20;
    --bgColor-open-muted: #d2fedb;
    --bgColor-open-emphasis: #055d20;
    --bgColor-attention-muted: #fcf7be;
    --bgColor-attention-emphasis: #744500;
    --bgColor-severe-muted: #fff2d5;
    --bgColor-severe-emphasis: #873800;
    --bgColor-danger-muted: #fff0ee;
    --bgColor-danger-emphasis: #a0111f;
    --bgColor-closed-muted: #fff0ee;
    --bgColor-closed-emphasis: #a0111f;
    --bgColor-done-muted: #faf0fe;
    --bgColor-done-emphasis: #622cbc;
    --bgColor-upsell-muted: #faf0fe;
    --bgColor-upsell-emphasis: #622cbc;
    --bgColor-sponsors-muted: #feeff7;
    --bgColor-sponsors-emphasis: #971368;
    --borderColor-default: #454c54;
    --borderColor-muted: #454c54;
    --borderColor-emphasis: #454c54;
    --borderColor-disabled: #59636e1f;
    --borderColor-transparent: #ffffff00;
    --borderColor-translucent: #59636e;
    --borderColor-neutral-muted: #454c54;
    --borderColor-neutral-emphasis: #59636e;
    --borderColor-accent-muted: #368cf9;
    --borderColor-accent-emphasis: #0349b4;
    --borderColor-success-muted: #26a148;
    --borderColor-success-emphasis: #055d20;
    --borderColor-open-muted: #26a148;
    --borderColor-open-emphasis: #055d20;
    --borderColor-attention-muted: #b58407;
    --borderColor-attention-emphasis: #744500;
    --borderColor-severe-muted: #dc6d1a;
    --borderColor-severe-emphasis: #873800;
    --borderColor-danger-muted: #ee5a5d;
    --borderColor-danger-emphasis: #a0111f;
    --borderColor-closed-muted: #ee5a5d;
    --borderColor-closed-emphasis: #a0111f;
    --borderColor-done-muted: #a371f7;
    --borderColor-done-emphasis: #622cbc;
    --borderColor-upsell-muted: #a371f7;
    --borderColor-upsell-emphasis: #622cbc;
    --borderColor-sponsors-muted: #ed4baf;
    --borderColor-sponsors-emphasis: #971368;
    --color-ansi-black: #010409;
    --color-ansi-black-bright: #393f46;
    --color-ansi-white: #59636e;
    --color-ansi-white-bright: #818b98;
    --color-ansi-gray: #59636e;
    --color-ansi-red: #a0111f;
    --color-ansi-red-bright: #86061d;
    --color-ansi-green: #024c1a;
    --color-ansi-green-bright: #055d20;
    --color-ansi-yellow: #3f2200;
    --color-ansi-yellow-bright: #4e2c00;
    --color-ansi-blue: #0349b4;
    --color-ansi-blue-bright: #1168e3;
    --color-ansi-magenta: #622cbc;
    --color-ansi-magenta-bright: #844ae7;
    --color-ansi-cyan: #1b7c83;
    --color-ansi-cyan-bright: #3192aa;
    --color-prettylights-syntax-comment: #59636e;
    --color-prettylights-syntax-constant: #023b95;
    --color-prettylights-syntax-constant-other-reference-link: #032563;
    --color-prettylights-syntax-entity: #512598;
    --color-prettylights-syntax-storage-modifier-import: #010409;
    --color-prettylights-syntax-entity-tag: #023b95;
    --color-prettylights-syntax-keyword: #a0111f;
    --color-prettylights-syntax-string: #032563;
    --color-prettylights-syntax-variable: #702c00;
    --color-prettylights-syntax-brackethighlighter-unmatched: #6e011a;
    --color-prettylights-syntax-brackethighlighter-angle: #59636e;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-invalid-illegal-bg: #6e011a;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-carriage-return-bg: #a0111f;
    --color-prettylights-syntax-string-regexp: #024c1a;
    --color-prettylights-syntax-markup-list: #2e1800;
    --color-prettylights-syntax-markup-heading: #023b95;
    --color-prettylights-syntax-markup-italic: #010409;
    --color-prettylights-syntax-markup-bold: #010409;
    --color-prettylights-syntax-markup-deleted-text: #6e011a;
    --color-prettylights-syntax-markup-deleted-bg: #fff0ee;
    --color-prettylights-syntax-markup-inserted-text: #024c1a;
    --color-prettylights-syntax-markup-inserted-bg: #d2fedb;
    --color-prettylights-syntax-markup-changed-text: #702c00;
    --color-prettylights-syntax-markup-changed-bg: #ffc67b;
    --color-prettylights-syntax-markup-ignored-text: #d1d9e0;
    --color-prettylights-syntax-markup-ignored-bg: #023b95;
    --color-prettylights-syntax-meta-diff-range: #622cbc;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #818b98;
    --shadow-inset: inset 0px 1px 0px 0px #0104090a;
    --shadow-resting-xsmall: 0px 1px 0px 0px #0104091a;
    --shadow-resting-small: 0px 1px 0px 0px #0104090a;
    --shadow-resting-medium: 0px 3px 6px 0px #25292e1f;
    --shadow-floating-small: 0px 0px 0px 1px #454c5480, 0px 6px 12px -3px #25292e0a, 0px 6px 18px 0px #25292e1f;
    --shadow-floating-medium: 0px 0px 0px 1px #454c54, 0px 8px 16px -4px #25292e14, 0px 4px 32px -4px #25292e14, 0px 24px 48px -12px #25292e14, 0px 48px 96px -24px #25292e14;
    --shadow-floating-large: 0px 0px 0px 1px #454c54, 0px 40px 80px 0px #25292e3d;
    --shadow-floating-xlarge: 0px 0px 0px 1px #454c54, 0px 56px 112px 0px #25292e52;
    --shadow-floating-legacy: 0px 6px 12px -3px #25292e0a, 0px 6px 18px 0px #25292e1f;
  }
}
